.bttn {
  border: none;
  outline: none;
  padding: 11px 12px;
  border-radius: 8px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  width: 100px;
  gap: 6px;
  cursor: pointer;
  background-image: linear-gradient(to right, #2c3e50, #4ca1af);
  box-shadow: 0 1px 2px #2c3e50;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bttn:hover {
  background-image: linear-gradient(to right, #466179, #67b6c1);
  transition: all 0.4s ease-in-out;
}

.form-container {
  position: relative;
  flex-wrap: wrap;
  align-items: center;
}

.form-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 11px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 550;
  font-size: 14px;
  width: 600px;
  margin-right: 10px;
  color: #242526;
}

.spinner-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
}

.spinner {
  margin-top: 20px;
  font-size: 3rem;
}

.message {
  margin-top: 20px;
  font-size: calc(7px + 2vmin);
}

.error-message {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 100%;
  margin-top: 20px;
  color: #b94a48;
  font-size: 1.75rem;
}

@media screen and (max-width: 750px) {
  .form-input {
    width: 100%;
  }
  .bttn {
    margin-top: 10px;
  }
  .error-message {
    font-size: 1.4rem;
  }
  .spinner {
    margin-top: 15px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 550px) {
  .error-message {
    font-size: 1.25rem;
  }
  .spinner {
    margin-top: 10px;
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 420px) {
  .form-input {
    width: 80%;
    max-width: 300px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 375px) {
  .error-message {
    font-size: 1rem;
  }
}
