.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.7s ease;
}

.modal {
  position: relative;
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #242526;
  font-size: 25px;
  max-width: 80%;
  max-height: 85vh;
  overflow-y: auto;
}

.modal h2 {
  margin-top: 0;
  text-align: center;
}

.modal-bttn {
  margin-top: 20px;
  border: none;
  outline: none;
  padding: 9px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  width: 100px;
  gap: 6px;
  cursor: pointer;
  background-image: linear-gradient(to right, #2c3e50, #4ca1af);
  box-shadow: 0 1px 2px #2c3e50;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.modal-bttn:hover {
  background-image: linear-gradient(to right, #466179, #67b6c1);
  transition: all 0.4s ease-in-out;
}

.message-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-icon {
  margin-left: 10px;
  padding-bottom: 47px;
  font-size: 34px;
  color: #242526;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 817px) {
  .modal-icon {
    margin-left: 8px;
    padding-bottom: 26px;
    font-size: calc(12px + 2vmin);
  }
  .modal h2 {
    font-size: calc(12px + 2vmin);
  }
}

@media screen and (max-width: 500px) {
  .modal-icon {
    visibility: hidden;
  }
  .modal {
    max-height: 50vh;
  }
}
