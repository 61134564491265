.App {
  text-align: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.dim-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  color: white;
}

.message-items {
  display: flex;
  align-items: center;
}

.icon-container {
  display: inline-block;
  vertical-align: middle;
}

.icon {
  margin-left: 10px;
  padding-bottom: 7px;
}

@media screen and (max-width: 750px) {
  .App-header {
    font-size: calc(11px + 2vmin);
  }
  .icon {
    margin-left: 5px;
    padding-bottom: 3px;
  }
}

@media screen and (max-width: 420px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }
}

@media screen and (max-width: 350px) {
  .App-header {
    font-size: calc(7px + 2vmin);
  }
  .icon {
    margin-left: 2.5px;
    padding-bottom: 2px;
  }
}
